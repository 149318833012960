import { useAppraisalVehByIdCoreQuery } from 'graphql-types';
import { RoutesConfig } from 'router/routes.config';
import { useParams } from 'react-router';
import useStore from 'store/pe-edit-appraisal.store';
import { useFeedback } from 'hooks/useFeedback';
import { useNavigate } from 'react-router-dom';

const useAppraisalQuery = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const store = useStore();
  const { showInfo } = useFeedback();

  return useAppraisalVehByIdCoreQuery(
    id
      ? {
          variables: { id },
          notifyOnNetworkStatusChange: true,

          onCompleted: ({ appraisalVehByIdCore: { result } }) => {
            store.setDefault(result as any);
          },

          onError: () => {
            showInfo(
              'Appraisal with this VIN is not found in the database, please create a new one'
            );

            return navigate(RoutesConfig.PhysicalEvaluation.Create);
          },
        }
      : { skip: true }
  );
};

export default useAppraisalQuery;
